<template>
    <div id="ItemContainer" class="margintop">
        <div id="TopbarContainer">
            <h1 id="Title" class="px-1">Linha do Tempo</h1>
        </div>
        <div id="ItemDisplay">
            <center>
                <v-img :src=this.linhaTempoURL />
            </center>
        </div>
    </div>
</template>

<style>
@import "../About.css";
</style>

<script>

import { http } from "../../../http-common"

export default {
    name: "timeline",
    components: {

    },
    data: () => {
        return {
            linhaTempoURL: "",
        };
    },
    methods: {
        getLinhaTempoURL: async function () {
            await this.$http.get("/SaibaMais/GetLinhaTempoAtual")
            .then(result => {
                this.linhaTempoURL = result.data.caminhoImagem.replace('wwwroot', http.defaults.baseURL);
                console.log(this.linhaTempoURL);
            })
            .catch(error => {
                this.$swal("Erro", error.response.data, "error");
            });
        }
    },
    mounted: function () {
        this.getLinhaTempoURL();
    }
}
</script>